import { Button, Checkbox, Col, Card, Form, Input, Row, Collapse, Select, notification, Modal, Table, Space, theme, Steps, Tabs, List } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined, ReloadOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import * as animationData from "../serviceanim.json";
import Lottie from "react-lottie";
import { validateEmail } from "../utils/methods";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";
import { useNavigate } from "react-router-dom";
import CreateServiceRequest from "./CreateServiceRequest";
import Address from "./Address";

const UserAddresses = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingShipping, setIsCreatingShipping] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState({ bAddress: { country: "India" }, sAddress: { country: "India" } });
  const [isGettingSavedAddresses, setIsGettingSavedAddresses] = useState(false);
  const [isGettingStatusIndi, setIsGettingStatusIndi] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { Panel } = Collapse;
  const { Search } = Input;

  const getUserDetails = async () => {
    try {
      let response = await axios.get(API_ENDPOINT + "/users/detail");
      if (response.data.user) {
        if (!response.data.user.bAddress) {
          response.data.user.bAddress = {};
        }
        if (!response.data.user.sAddress) {
          response.data.user.sAddress = [];
        }
        setUser(response.data.user);
      }
    } catch (err) {}
  };

  const saveBillingAddress = async () => {
    try {
      if (!user.bAddress["country"]) {
        user.bAddress.country = "India";
      }
      setIsCreating(true);
      let response = await axios.post(API_ENDPOINT + "/users/addresses/billing", { ...user["bAddress"] });
      api.success({
        message: "eGlu Service Portal",
        description: response.data?.message ?? "Billing address is saved successfully",
      });
      setIsCreating(false);
    } catch (err) {
      api.error({
        message: "eGlu Service Portal",
        description: err.response?.data?.message ?? "Failed to save billing address. Please try again",
      });
      setIsCreating(false);
    }
  };
  const saveShippingAddress = async () => {
    try {
      if (!user.sAddress["country"]) {
        user.sAddress.country = "India";
      }
      setIsCreatingShipping(true);
      let response = await axios.post(API_ENDPOINT + "/users/addresses/shipping", { ...user["sAddress"] });
      api.success({
        message: "eGlu Service Portal",
        description: response.data?.message ?? "Shipping address is saved successfully",
      });
      setIsCreatingShipping(false);
    } catch (err) {
      api.error({
        message: "eGlu Service Portal",
        description: err.response?.data?.message ?? "Failed to save shipping address. Please try again",
      });
      setIsCreatingShipping(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Fragment>
      <Col span={24}>
        {contextHolder}
        <Collapse>
          <Panel header="Billing Address" key="1">
            <Address
              type="billing"
              address={user.bAddress}
              onAddressChange={(address) => {
                setUser({ ...user, bAddress: address });
              }}
              isFromUserProfile={true}
            />
            <Button
              type="primary"
              loading={isCreating}
              style={{ width: "100%", backgroundColor: "#ae9572", marginTop: 10, color: "black" }}
              onClick={() => {
                saveBillingAddress();
              }}
            >
              Save Billing Address
            </Button>
          </Panel>
        </Collapse>
        <br />
        <Collapse>
          <Panel header="Shipping Address" key="1">
            <Address
              type="shipping"
              address={user.sAddress}
              onAddressChange={(address) => {
                setUser({ ...user, sAddress: address });
              }}
              isFromUserProfile={true}
            />
            <Button
              type="primary"
              loading={isCreatingShipping}
              style={{ width: "100%", backgroundColor: "#ae9572", marginTop: 10, color: "black" }}
              onClick={() => {
                saveShippingAddress();
              }}
            >
              Save Shipping Address
            </Button>
          </Panel>
        </Collapse>
      </Col>
    </Fragment>
  );
};

export default UserAddresses;
