const SAVED_B_ADDRESS_KEY = "eglu_service_b_addresses";
const SAVED_S_ADDRESS_KEY = "eglu_service_s_addresses";

const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

const isValidGSTIN = (GSTIN) => {
  let gst = GSTIN.replaceAll("-", "").replaceAll("URP", "");
  if (gst === "") {
    return false;
  } else if (new RegExp("^[0]+$").test(gst)) {
    return false;
  } else if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst)) {
    return false;
  }
  return true;
};

export { SAVED_B_ADDRESS_KEY, SAVED_S_ADDRESS_KEY, isValidEmail, isValidGSTIN };
