import * as React from "react";
const EgluLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 184 48.5",
    }}
    viewBox="0 0 184 48.5"
    {...props}
  >
    <style>{`.st0{fill-rule:evenodd;clip-rule:evenodd}.st0,.st1{fill:${props.color}}`}</style>
    <path
      d="M53.9 27.3c-.6-4.8-.9-9.6-5.9-16.7C42.6 3 33.3.3 28.5.3s-11.1 2-14.4 4.3C9.2 8 6 11.4 3.2 19.5c-2.1 6.1-2.1 20.2-2.1 20.2s18.5 8.6 18.5 8.5c0-2-.6-13.3 1.1-19.6 2.3-8.2 9.6-13.3 15.2-12.8 6.8.6 9.3 3.7 10.8 5.7 2.5 3.1 3.7 8.2 3.7 15.3 0 1.4.1 10.6.1 10.6l3.5-5.1c.1-.1.5-10.2-.1-15m-15.3-3.8c-1.7 0-8.2.2-9.7 11.3V44c.2.1 8.7 4.1 8.8 4.1V38c0-3.4 1.6-4.6 3.4-4.7 2.3 0 3.8 1.6 3.8 4.7v8.7l3.4 1.3s0-9.2-.1-11.1c-.1-6.6-3.1-13.4-9.6-13.4"
      className="st0"
    />
    <path
      d="M134.8 41.9c-2.5 2.1-5.4 3.6-8.5 4.7-3.1 1.1-6.4 1.6-9.8 1.6-2.6 0-5-.3-7.2-1s-4.2-1.7-5.9-3c-2.3-1.8-4.1-3.9-5.2-6.2-1.1-2.4-1.7-5-1.7-8.1 0-2.6.6-5.1 1.7-7.5 1.2-2.4 2.8-4.4 4.8-6.1 1.8-1.5 3.9-2.6 6.2-3.3 2.3-.7 4.8-1.1 7.4-1.1 3.4 0 6.4.6 8.9 1.9 2.5 1.3 4.7 3.2 6.6 5.9l-4.7 3.2c-1.1-2.2-2.5-3.9-4.1-5.1-1.6-1.1-3.5-1.7-5.6-1.7-3.2 0-5.7 1.2-7.4 3.6-1.7 2.4-2.6 5.8-2.6 10.3 0 4 1 7.3 3 9.8s4.5 3.8 7.7 3.8c1.2 0 2.3-.2 3.3-.5s2-.9 3-1.5v-9.7h10.1v10zM148.1 40.3c0 1.4.3 2.4.8 2.9s1.5.8 2.9.8h2v3.5H147c-2.5 0-4.3-.5-5.3-1.5s-1.5-2.9-1.5-5.5V10.2h8l-.1 30.1zM157.2 24.3h8v14.1c0 2.1.3 3.6.9 4.4.6.8 1.6 1.3 3 1.3 1.5 0 2.5-.4 3.1-1.3.6-.9.9-2.3.9-4.4V24.3h4v14.9c0 3-.8 5.2-2.5 6.7-1.6 1.5-4.1 2.2-7.5 2.2s-5.9-.7-7.5-2.2-2.4-3.7-2.4-6.8V24.3z"
      className="st1"
    />
    <path d="M85.3 43.2c-3.4.7-7.2-1.9-8.1-3.9 0-.1 16.7-5.2 16.7-5.2s-1-7-9.2-9.1c-6.7-1.6-14.1 1.3-15.5 9.6S74 46 78.4 47.3c6.2 1.7 10.9-1.2 12.8-3.1.1-.1-1.9-3-1.9-3s-2.1 1.6-4 2m-7.2-13.6c2.2-2 4.9-1.6 5.8-1.5 2.7 0 5.6 3 5.3 3.8-.1.1-12.9 4.1-12.9 4.1-.3-.2-.8-4.1 1.8-6.4" className="st0" />
  </svg>
);
export default EgluLogo;
