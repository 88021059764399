import { Button, Checkbox, Col, Card, Form, Avatar, Input, Row, Collapse, Select, notification, Modal, Table, Space, theme, Steps, Tabs, List, Badge } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined, ReloadOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import * as animationData from "../serviceanim.json";
import Lottie from "react-lottie";
import { validateEmail } from "../utils/methods";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";
import { useNavigate } from "react-router-dom";
import CreateServiceRequest from "./CreateServiceRequest";
import Address from "./Address";

const Profile = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingShipping, setIsCreatingShipping] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState({ bAddress: { country: "India" }, sAddress: { country: "India" } });
  const [isGettingSavedAddresses, setIsGettingSavedAddresses] = useState(false);
  const [isGettingStatusIndi, setIsGettingStatusIndi] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { Panel } = Collapse;
  const { Search } = Input;

  const getUserDetails = async () => {
    try {
      let response = await axios.get(API_ENDPOINT + "/users/detail");
      console.log(response.data);
      if (response.data.user) {
        if (!response.data.user.bAddress) {
          response.data.user.bAddress = {};
        }
        if (!response.data.user.sAddress) {
          response.data.user.sAddress = [];
        }
        setUser(response.data.user);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Fragment>
      <Col span={24} style={{ marginTop: 30 }}>
        {contextHolder}
        <Row align="middle" justify="center" gutter={{ sm: 10, md: 10 }}>
          <Badge.Ribbon text={user.role ?? "User"} color="#ae9572">
            <Card title="Account Information" style={{ marginLeft: 20, marginTop: -15, width: 300, height: 150 }}>
              <Row style={{ marginTop: -10 }}>
                <Avatar size={64} icon={<UserOutlined />} />
                <Col style={{ marginTop: -10, marginLeft: 20 }}>
                  <h3>{user.name}</h3>
                  <h6 style={{ marginTop: -20 }}>{user.email}</h6>
                </Col>
              </Row>
            </Card>
          </Badge.Ribbon>
        </Row>
      </Col>
    </Fragment>
  );
};

export default Profile;
