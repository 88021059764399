import { Button, Checkbox, Col, Card, Form, Input, Row, Collapse, Select, notification, Modal, Table, Space, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as animationData from "../serviceanim.json";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";

const Login = () => {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    let { email, password } = values;
    try {
      setIsCreating(true);
      let response = await axios.post(API_ENDPOINT + "/users/login", { email, password });
      setIsCreating(false);
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        navigate("/dashboard");
      } else {
        api.error({
          message: "eGlu Service Login",
          description: response.data.message ?? "Failed to login Account. Please try again",
        });
      }
    } catch (err) {
      console.log(err);
      setIsCreating(false);
      api.error({
        message: "eGlu Service Login",
        description: err.response?.data?.message ?? "Failed to login Account. Please try again",
      });
    }
  };

  const onFinishFailed = () => {};

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
      {contextHolder}
      <Col style={{ textAlign: "center" }}>
        <Lottie options={defaultOptions} height={100} width={100} />
        <h2 style={{ color: token.colorText }}>eGlu Service Login</h2>
        <div style={{ textAlign: "center", padding: 20 }} className="glass">
          <Form name="basic" style={{ maxWidth: 550 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
            <Form.Item label="Email ID" name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isCreating}>
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Link style={{ color: token.colorText, textDecoration: "underline" }} to={`/forgotpassword`}>
            Forgot Password?
          </Link>
        </div>
        <h4 style={{ color: token.colorText }}>
          Don't have an account?{" "}
          <a href="/signup" style={{ color: token.colorText, textDecoration: "underline" }}>
            Sign Up
          </a>
        </h4>
      </Col>
    </Row>
  );
};

export default Login;
