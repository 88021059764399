import { Card, Input, Button, Tag } from "antd";
import { useState } from "react";

const ContactNotifier = (props) => {
  const { onEmailIdChange, onEmailDelete, contacts } = props;
  const [emailId, setEmailId] = useState("");
  return (
    <Card title="Additional Notifying Email Ids" style={{ marginTop: "20px" }} className="glass">
      <Input
        placeholder="Enter Email ID"
        value={emailId}
        onChange={(event) => {
          setEmailId(event.target.value);
        }}
        allowClear={true}
        onKeyDown={(event) => {
          if (event.key == "Enter") {
            onEmailIdChange(emailId);
            setEmailId("");
          }
        }}
      />
      <Button
        type="primary"
        style={{ width: "100%", marginTop: 30, backgroundColor: "#ae9572", color: "black" }}
        onClick={() => {
          onEmailIdChange(emailId);
          setEmailId("");
        }}
      >
        Add Email ID
      </Button>
      <div style={{ marginTop: 20 }}>
        {contacts.map((contact) => {
          return (
            <Tag closable onClose={onEmailDelete} key={contact}>
              {contact}
            </Tag>
          );
        })}
      </div>
    </Card>
  );
};

export default ContactNotifier;
