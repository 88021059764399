import { Fragment, useState, useRef, Context } from "react";
import { Col, Input, Button, Card, Table, Space, notification, Select, Badge } from "antd";
import { chunkSubstr } from "../utils/methods";

const AdditionalCharges = (props) => {
  const [siteVisitCharge, setSiteVisitCharge] = useState(undefined);

  const [api, contextHolder] = notification.useNotification();

  const { onSiteVisitChargeChangeMain } = props;

  function onSiteVisitChargeChange(event) {
    console.log("onSiteVisitChargeChange from address", event.target.value);
    setSiteVisitCharge(event.target.value);
    onSiteVisitChargeChangeMain(event.target.value);
  }

  return (
    <Badge.Ribbon text={"New"} color="#87d068">
      <Card
        className="glass"
        title="Additioanal Charges"
        style={{
          width: "100%",
          marginTop: 20,
        }}
      >
        {contextHolder}
        <Col>
          <Input onChange={onSiteVisitChargeChange} placeholder="Site Visit / Installation Charge" style={{ marginBottom: "10px" }} type="number" />
        </Col>
      </Card>
    </Badge.Ribbon>
  );
};

export default AdditionalCharges;
